// Chakra imports
import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Select,
  Textarea,
  Link,
  Switch,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import React, {useState, useEffect} from "react";
import { Link as RouterLink } from "react-router-dom";
import { FaUserGraduate, FaUser } from "react-icons/fa";
import axios from "axios";
import { ENDPOINTS } from "../../../api/constants.js";
import moment from "moment";

function Case(props) {
  const titleColor = useColorModeValue("blue", "blue.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const bgIcons = useColorModeValue("blue", "rgba(255, 255, 255, 0.5)");
  const [loading, setLoading] = useState(true);
  const [caseDetail, setCaseDetails] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem('userDetails'));
      const onboardedLawyer = JSON.parse(localStorage.getItem('lawyerOnboarded'));

      if(user.is_customer === false && onboardedLawyer === false) {
        props.history.push('/admin/lawyer-onboard');
      }

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      };

      const url = process.env.REACT_APP_API_BASE + ENDPOINTS.cases + '/admin/single-case/' + props.match.params.caseId + '/';

      axios
        .get(url, config)
        .then((res) => {
          setLoading(false);
          setCaseDetails(res.data.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error);
            setLoading(false);
          } else if (error.request) {
            console.log(error);
            setLoading(false);
          } else {
            console.log(error);
            setLoading(false);
          }
        });
    } catch (error) {
      console.log(error);

    }
  }, []);

  return (
    <Flex
      direction='column'
      alignSelf='center'
      justifySelf='center'
      overflow='hidden'>
      <Flex direction='column' alignItems='center' justifyContent='center' mb='60px' mt='20px'>
        {
           loading ? (
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue'
              size='xl'
              mt='5rem'
            />
          ) : (
            Object.keys(caseDetail).length > 0 ? (
              <>
                <Button
                  fontSize='10px'
                  bg='blue'
                  w='30%'
                  h='45'
                  mb='20px'
                  color='white'
                  mt='5rem'
                  _hover={{
                    bg: "blue.200",
                  }}
                  _active={{
                    bg: "blue.400",
                  }}
                  onClick={() => props.history.push('/admin/assign/lawyer/' + props.match.params.caseId)}>Assign Case</Button>
                <Flex
                  direction='column'
                  w='95%'
                  background='transparent'
                  borderRadius='15px'
                  p='40px'
                  bg={bgColor}
                  boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
                  <Text
                    fontSize='xl'
                    color={textColor}
                    fontWeight='bold'
                    textAlign='center'
                    mb='5px'>
                    Case Details
                  </Text>
                  <Text
                    fontSize='sm'
                    color='gray.400'
                    fontWeight='normal'
                    textAlign='center'
                    mb='15px'>
                    Submitted {moment(caseDetail.createdAt).fromNow()} by {caseDetail.userId?.firstName + ' ' + caseDetail.userId?.lastName}
                  </Text>

                  <Text
                    fontSize='sm'
                    color='black.400'
                    fontWeight='normal'
                    mb='15px'>
                    {caseDetail.description}
                  </Text>
                  
                </Flex>
              </>
            ) : (
              <Alert status='error' mb='24px' mt='5rem'>
                <AlertIcon />
                Could not fetch case <Link color={titleColor} as={RouterLink} ms='5px' fontWeight='bold' to="/admin/cases">
                  Go Back
                </Link>
              </Alert>
            )
          )
        }
      </Flex>
    </Flex>
  );
}

export default Case;
