// Chakra imports
import {
  Badge,
  Flex,
  Grid,
  GridItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import IconBox from "components/Icons/IconBox";
import {DocumentIcon} from "components/Icons/Icons";
import moment from "moment";
// import TablesProjectRow from "./TablesTableRow";
import React from "react";

const Projects = ({title, captions, data}) => {
  const textColor = useColorModeValue("gray.700", "white");
  const iconBoxInside = useColorModeValue("white", "white");
  const iconTeal = useColorModeValue("teal.300", "teal.300");

  return (
    <Card my="22px" overflowX={{sm: "scroll", xl: "hidden"}}>
      <CardHeader p="6px 0px 22px 0px">
        <Flex direction="column">
          <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
            {title}
          </Text>
        </Flex>
      </CardHeader>
      <CardBody>
        <Grid
          // columns={[1, 2, 3]}
          gap={4}
          my="22px"
          templateColumns="repeat(3, 1fr)"
        >
          {data?.map((dat) => (
            <GridItem colSpan={1} cursor={"pointer"}>
              <Card border={"0.5px solid #f3f3f3"} _hover={{bg: "#0001"}} onClick={() => window.location.href="/admin/case/" + dat._id}>
                <Flex py="3" gap={3} alignItems={"center"}>
                  <IconBox as="box" h={"45px"} w={"45px"} bg={iconTeal}>
                    <DocumentIcon h={"24px"} w={"24px"} color={iconBoxInside} />
                  </IconBox>
                  <Flex justifyContent={"space-between"} w={"100%"}>
                    <Flex flexDirection={"column"}>
                      <Text fontWeight={"bold"}>
                        {dat?.userId
                          ? dat?.userId?.firstName + " " + dat?.userId?.lastName
                          : "Anonymous User"}
                      </Text>
                      <Text fontSize={"12px"}>
                        Case #: {dat?._id?.slice(0, 6)}
                      </Text>
                    </Flex>
                    <div>
                      <Badge
                        size={10}
                        color={"teal.600"}
                        bg={"teal.50"}
                        rounded={"full"}
                        px={3}
                        py={1}
                      >
                        {dat?.status}
                      </Badge>
                    </div>
                  </Flex>
                </Flex>
                <Text noOfLines={[1, 2, 3]}>{dat?.description}</Text>

                <Flex mt={4}>
                  <Text color={"GrayText"} fontSize={14}>
                    created on: {moment(dat?.createdAt)?.format("DD MMM, YYYY")}
                  </Text>
                </Flex>
              </Card>
            </GridItem>
          ))}
        </Grid>
        {/* <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px">
              {captions.map((caption, idx) => {
                return (
                  <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, index) => {
              return (
                <TablesProjectRow
                  key={index}
                  id={row.id}
                  // customerName={
                  //   row.customer.first_name + " " + row.customer.last_name
                  // }
                  title={row.title}
                  description={row.description}
                  // logo={row.logo}
                  // lawyer={row.lawyer}
                  // lawyerName={row.lawyerName}
                  dateCreated={row.submitted_on}
                  status={row.status}
                  // progression={row.progression}
                />
              );
            })}
          </Tbody>
        </Table> */}
      </CardBody>
    </Card>
  );
};

export default Projects;
