function getFileExtension(fileName) {
  return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
}

export function checkFileType(fileName) {
  const fileExtension = getFileExtension(fileName).toLowerCase();
  console.log(fileExtension);
  if (
    fileExtension === "pdf" ||
    fileExtension === "docx" ||
    fileExtension === "doc"
  ) {
    return true;
  }
  return false;
}
