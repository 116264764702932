// Chakra imports
import {
  Avatar,
  CloseButton,
  Divider,
  Flex,
  Image,
  Text,
  Spinner,
  Stack,
  Grid,
  GridItem,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Authors from "./components/Authors";
import { useToast } from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import axios from "axios";
import { ENDPOINTS } from "../../../api/constants";
import pdf from "assets/img/pdf.png";
import { checkFileType } from "../../../components/utils";

function Lawyers(props) {
  const [loading, setLoading] = useState(true);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [data, setData] = useState([]);
  const [singleData, setSingleData] = useState({});
  const [selected, setSelected] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const [showDetailsCard, setShowDetailsCard] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const toast = useToast();

  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem("userDetails"));
      const onboardedLawyer = JSON.parse(
        localStorage.getItem("lawyerOnboarded")
      );

      if (user.is_customer === false && onboardedLawyer === false) {
        props.history.push("/admin/lawyer-onboard");
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };

      const url = process.env.REACT_APP_API_BASE + ENDPOINTS.lawyers;

      axios
        .get(url, config)
        .then((res) => {
          setLoading(false);
          setData(res.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error);
            setLoading(false);
          } else if (error.request) {
            console.log(error);
            setLoading(false);
          } else {
            console.log(error);
            setLoading(false);
          }
        });
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const openSingleItem = (datafromTable) => {
    setShowDetailsCard(true);
    setSingleData(datafromTable);
    setSelected(true);
    setActiveIndex(datafromTable?._id);
    setSelectedId(datafromTable._id);
  };

  return (
    <Flex direction="column" pt={{base: "120px", md: "75px"}}>
      {loading ? (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue"
          size="xl"
          ml="45%"
        />
      ) : (
        <>
          {data.length !== 0 ? (
            <>
              <Grid h="100%" templateColumns="repeat(6, 1fr)" gap={3}>
                <GridItem colSpan={showDetailsCard ? 4 : 6}>
                  <Authors
                    title={"Lawyers"}
                    captions={[
                      "Name",
                      "Phone Number",
                      "Area of Practice",
                      "Status",
                    ]}
                    data={data?.data}
                    activeIndex={activeIndex}
                    openSingleFunc={openSingleItem}
                  />
                </GridItem>
                {showDetailsCard ? (
                  <GridItem colSpan={2} height={"100%"}>
                    <Card p="16px" overflowX={{sm: "scroll", xl: "hidden"}}>
                      <Flex direction="row">
                        <CloseButton
                          onClick={() => {
                            setShowDetailsCard(false);
                            setActiveIndex(null);
                          }}
                        />
                      </Flex>
                      <CardHeader p="12px 0px 28px 0px">
                        <Flex direction="row">
                          <Text fontSize="lg" fontWeight="bold" pb=".5rem">
                            Lawyer Details
                          </Text>
                        </Flex>
                      </CardHeader>
                      <CardBody px="5px">
                        <Stack
                          direction="column"
                          w={"100%"}
                          alignItems="center"
                          h={"100%"}
                        >
                          <Avatar
                            name={
                              singleData?.userId
                                ? singleData?.userId.firstName +
                                  " " +
                                  singleData?.userId.lastName
                                : "Not Specified"
                            }
                            src={singleData.profile_picture}
                            size="xl"
                          />
                          <Divider mt={20} />
                          <Text
                            fontSize="sm"
                            color="gray.500"
                            fontWeight="bold"
                            alignSelf="flex-start"
                          >
                            Name:{" "}
                            {singleData?.userId
                              ? singleData?.userId.firstName +
                                " " +
                                singleData?.userId.lastName
                              : "Not Specified"}
                          </Text>
                          <Divider mt={20} />
                          <Text
                            fontSize="sm"
                            color="gray.500"
                            fontWeight="bold"
                            alignSelf="flex-start"
                          >
                            Area of Practice:{" "}
                            {singleData?.categoryId?.categoryName}
                          </Text>
                          <Divider mt={20} />
                          <Text
                            fontSize="sm"
                            color="gray.500"
                            fontWeight="bold"
                            alignSelf="flex-start"
                          >
                            Phone Number:{" "}
                            {singleData?.userId
                              ? singleData?.userId.phone
                              : "Not Specified"}
                          </Text>
                          <Divider mt={20} />
                          <Text
                            fontSize="sm"
                            color="gray.500"
                            fontWeight="bold"
                            alignSelf="flex-start"
                          >
                            Practicing Cert:
                          </Text>
                          {singleData?.practicingCertificate?.length === 0 ? (
                            <Text
                              fontSize="sm"
                              color="gray.500"
                              fontWeight="bold"
                              alignSelf="flex-start"
                            >
                              No practicing certificate uploaded
                            </Text>
                          ) : checkFileType(
                              singleData?.practicingCertificate?.[0]
                            ) ? (
                            <>
                              <a
                                href={singleData.practicingCertificate?.[0]}
                                target="_blank"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  paddingTop: "5rem",
                                  paddingBottom: "5rem",
                                  backgroundColor: "#f4f7fa",
                                  borderRadius: "8px",
                                  cursor: "pointer",
                                }}
                              >
                                <Image
                                  height={20}
                                  src={pdf}
                                  alt="Practicing Cert"
                                />
                              </a>
                            </>
                          ) : (
                            <Image
                              width={"100%"}
                              src={singleData.practicingCertificate?.[0]}
                              alt="Practicing Cert"
                            />
                          )}
                          <Divider mt={20} />
                          <Text
                            fontSize="sm"
                            color="gray.500"
                            fontWeight="bold"
                            alignSelf="flex-start"
                          >
                            Curriculum Vitae:
                          </Text>

                          {singleData?.curriculumVitae?.length === 0 ? (
                            <Text
                              fontSize="sm"
                              color="gray.500"
                              fontWeight="bold"
                              alignSelf="flex-start"
                            >
                              No Curriculum Vitae uploaded
                            </Text>
                          ) : checkFileType(
                              singleData?.curriculumVitae?.[0]
                            ) ? (
                            <a
                              href={singleData.curriculumVitae[0]}
                              target="_blank"
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingTop: "5rem",
                                paddingBottom: "5rem",
                                backgroundColor: "#f4f7fa",
                                borderRadius: "8px",
                                cursor: "pointer",
                              }}
                            >
                              <Image
                                height={20}
                                src={pdf}
                                alt="Practicing Cert"
                              />
                            </a>
                          ) : (
                            <Image
                              width={"100%"}
                              src={singleData.curriculumVitae[0]}
                              alt="Curriculum Vitae"
                            />
                          )}
                        </Stack>
                      </CardBody>
                    </Card>
                  </GridItem>
                ) : null}
              </Grid>
            </>
          ) : (
            <Card p="16px" overflowX={{sm: "scroll", xl: "hidden"}}>
              <CardHeader p="12px 0px 28px 0px">
                <Flex direction="column">
                  <Text fontSize="lg" fontWeight="bold" pb=".5rem">
                    Lawyers
                  </Text>
                  <Flex align="center">
                    <Text fontSize="sm" color="gray.400" fontWeight="normal">
                      <Text fontWeight="bold" as="span">
                        Your lawyers will be listed here
                      </Text>
                    </Text>
                  </Flex>
                </Flex>
              </CardHeader>
              <CardBody px="5px">
                <Text
                  fontSize="sm"
                  color="gray.500"
                  fontWeight="bold"
                  textAlign={"center"}
                >
                  No lawyers yet
                </Text>
              </CardBody>
            </Card>
          )}
        </>
      )}
    </Flex>
  );
}

export default Lawyers;
