// Chakra imports
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Avatar,
  Box,
  Button,
  CloseButton,
  Divider,
  Flex,
  Image,
  Text,
  Spinner,
  Stack,
  Grid,
  GridItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Authors from "./components/Authors";
import { tablesTableData } from "variables/general";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import axios from "axios";
import { ENDPOINTS } from "../../../api/constants";

function Verifications(props) {
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isOpenPdf, setIsOpenPdf] = useState(false);
  const [data, setData] = useState([]);
  const [singleData, setSingleData] = useState({});
  const [selected, setSelected] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const [showDetailsCard, setShowDetailsCard] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [successVerification, setSuccessVerification] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem("userDetails"));

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };

      const url = process.env.REACT_APP_API_BASE + ENDPOINTS.lawyers;

      axios
        .get(url, config)
        .then((res) => {
          setLoading(false);
          setData(res.data.data);
          console.log(res.data.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error);
            setLoading(false);
          } else if (error.request) {
            console.log(error);
            setLoading(false);
          } else {
            console.log(error);
            setLoading(false);
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const openSingleItem = (id) => {
    setShowDetailsCard(true);

    let index = data.findIndex((item) => {
      return id === item._id;
    });

    if (index !== -1) {
      setSingleData(data[index]);
      setSelected(data[index] ? true : false);
      setActiveIndex(index);
      setSelectedId(id);
    } else {
      console.log("Cannot be found");
    }
  };

  const verifyLawyer = () => {
    try {
      setVerifyLoading(true);
      const user = JSON.parse(localStorage.getItem("userDetails"));

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };

      const verifyData = {
        instructVerified: true,
      };

      const url =
        process.env.REACT_APP_API_BASE +
        ENDPOINTS.lawyers +
        "/verify/" +
        singleData._id;

      axios
        .patch(url, verifyData, config)
        .then((res) => {
          setVerifyLoading(false);
          setSuccessVerification(true);
          setShowDetailsCard(false);

          var refArr = [];
          var newArr = refArr.concat(data);
          var itemIndex = newArr.findIndex((item) => item._id == singleData._id);
          newArr.splice(res.data.data, itemIndex);

          setData(newArr);

          window.location.reload();

          onClose();
          setActiveIndex(null);
          setSuccessVerification(true);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error);
            setVerifyLoading(false);
          } else if (error.request) {
            console.log(error);
            setVerifyLoading(false);
          } else {
            console.log(error);
            setVerifyLoading(false);
          }
        });
    } catch (error) {
      console.log(error);
      setVerifyLoading(false);
    }
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      {successVerification ? (
        <Alert status="success" mb={"2rem"}>
          <AlertIcon />
          <Box>
            <AlertTitle>Success!</AlertTitle>
            <AlertDescription>
              Lawyer has been successfully verified. You can find them on the
              lawyers page or when you search for them.
            </AlertDescription>
          </Box>
          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
            onClick={setSuccessVerification(false)}
          />
        </Alert>
      ) : null}

      {loading ? (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue"
          size="xl"
          ml="45%"
        />
      ) : (
        <>
          {data.length !== 0 ? (
            <>
              <Grid h="200px" templateColumns="repeat(6, 1fr)" gap={3}>
                <GridItem colSpan={showDetailsCard ? 4 : 6}>
                  <Authors
                    title={"Pending Verifications"}
                    captions={[
                      "Name",
                      "Phone Number",
                      "Category Selected",
                      "Status",
                    ]}
                    data={data}
                    activeIndex={activeIndex}
                    openSingleFunc={openSingleItem}
                  />
                </GridItem>
                {showDetailsCard ? (
                  <GridItem colSpan={2}>
                    <Card p="16px" overflowX={{ sm: "scroll", xl: "hidden" }}>
                      <Flex direction="row">
                        <CloseButton
                          onClick={() => {
                            setShowDetailsCard(false);
                            setActiveIndex(null);
                          }}
                        />
                      </Flex>
                      <CardHeader p="12px 0px 28px 0px">
                        <Flex direction="row">
                          <Text fontSize="lg" fontWeight="bold" pb=".5rem">
                            Lawyer Details
                          </Text>
                        </Flex>
                      </CardHeader>
                      <CardBody px="5px">
                        <Stack
                          direction="column"
                          w={"100%"}
                          alignItems="center"
                        >
                          <Avatar
                            name={
                              singleData?.userId?.firstName +
                              " " +
                              singleData?.userId?.lastName
                            }
                            src={singleData.profile_picture}
                            size="xl"
                          />
                          <Divider mt={20} />
                          <Text
                            fontSize="sm"
                            color="gray.500"
                            fontWeight="bold"
                            alignSelf="flex-start"
                          >
                            Name:{" "}
                            {singleData?.userId?.firstName +
                              " " +
                              singleData?.userId?.lastName}
                          </Text>
                          <Divider mt={20} />
                          <Text
                            fontSize="sm"
                            color="gray.500"
                            fontWeight="bold"
                            alignSelf="flex-start"
                          >
                            Phone Number: {singleData?.userId?.phone}
                          </Text>
                          <Divider mt={20} />
                          <Text
                            fontSize="sm"
                            color="gray.500"
                            fontWeight="bold"
                            alignSelf="flex-start"
                          >
                            Practicing Cert:
                          </Text>
                          <Button
                            onClick={() => setIsOpenPdf(true)}
                            colorScheme="blue"
                          >
                            View
                          </Button>
                          <Divider mt={20} />
                          <Button onClick={onOpen} colorScheme="green">
                            Verify
                          </Button>
                        </Stack>
                      </CardBody>
                    </Card>

                    <Modal
                      isOpen={isOpenPdf}
                      onClose={() => setIsOpenPdf(false)}
                    >
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>Practicing Cert</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          <div>
                            <img
                              src={
                                singleData.practicingCertificate
                                  ? singleData.practicingCertificate[0]
                                  : null
                              }
                              style={{ width: "100%" }}
                            />
                          </div>
                        </ModalBody>

                        <ModalFooter>
                          <Button
                            variant="ghost"
                            onClick={() => setIsOpenPdf(false)}
                          >
                            Close
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                  </GridItem>
                ) : null}
              </Grid>
            </>
          ) : (
            <Card p="16px" overflowX={{ sm: "scroll", xl: "hidden" }}>
              <CardHeader p="12px 0px 28px 0px">
                <Flex direction="column">
                  <Text fontSize="lg" fontWeight="bold" pb=".5rem">
                    Pending Verifications
                  </Text>
                  <Flex align="center">
                    <Text fontSize="sm" color="gray.400" fontWeight="normal">
                      <Text fontWeight="bold" as="span">
                        Your pending verifications will be listed here
                      </Text>
                    </Text>
                  </Flex>
                </Flex>
              </CardHeader>
              <CardBody px="5px">
                <Text
                  fontSize="sm"
                  color="gray.500"
                  fontWeight="bold"
                  textAlign={"center"}
                >
                  No pending verifications yet
                </Text>
              </CardBody>
            </Card>
          )}
        </>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Verification</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to verify this lawyer?</ModalBody>

          <ModalFooter>
            <Button
              isLoading={verifyLoading}
              loadingText="Verifying...."
              colorScheme="blue"
              onClick={verifyLawyer}
              mr={3}
            >
              Yes
            </Button>
            <Button variant="ghost" onClick={onClose}>
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default Verifications;
