// Chakra imports
import { 
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Avatar, 
  Box,
  Button,
  CloseButton,
  Divider,
  Flex,
  Image,
  Text,
  Spinner,
  Stack,
  Grid,
  GridItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Authors from "./components/Authors";
import { tablesTableData } from "variables/general";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import axios from "axios";
import { ENDPOINTS } from "../../../api/constants";

function CaseLawyers(props) {
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);
  const [singleData, setSingleData] = useState({});
  const [selected, setSelected] = useState('');
  const [activeIndex, setActiveIndex] = useState(null);
  const [showDetailsCard, setShowDetailsCard] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [assignLoading, setAssignLoading] = useState(false);
  const [successVerification, setSuccessVerification] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem('userDetails'));
      const onboardedLawyer = JSON.parse(localStorage.getItem('lawyerOnboarded'));

      if(user.is_customer === false && onboardedLawyer === false) {
        props.history.push('/admin/lawyer-onboard');
      }

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${user.access}`
        }
      };

      const url = process.env.REACT_APP_API_BASE + ENDPOINTS.firm + 'lawyers/?is_verified=true';

      axios
        .get(url, config)
        .then((res) => {
          setLoading(false);
          setData(res.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error);
            setLoading(false);
          } else if (error.request) {
            console.log(error);
            setLoading(false);
          } else {
            console.log(error);
            setLoading(false);
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const openSingleItem = (id) => {
    setShowDetailsCard(true);

    let index = data.findIndex(item => { return id == item.id });

    if (index !== -1) {
      setSingleData(data[index]);
      setSelected(data[index] ? true : false);
      setActiveIndex(index);
      setSelectedId(id);
    }
    else {
      console.log('Cannot be found');
    }
  };

  const assignLawyer = () => {
    try {
      setAssignLoading(true);
      const user = JSON.parse(localStorage.getItem('userDetails'));

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${user.access}`
        }
      };

      const data = {
        case_id: props.match.params.caseId,
        lawyer_id: selectedId
      };

      const url = process.env.REACT_APP_API_BASE + ENDPOINTS.firm + 'assigned-cases/';

      axios
        .post(url, data, config)
        .then((res) => {
          setAssignLoading(false);
          setSuccessVerification(true);
          // setShowDetailsCard(false);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error);
            setAssignLoading(false);
          } else if (error.request) {
            console.log(error);
            setAssignLoading(false);
          } else {
            console.log(error);
            setAssignLoading(false);
          }
        });
    } catch (error) {
      console.log(error);
      setVerifyLoading(false);
    };
  };

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      {
        successVerification ? (
          <Alert status='success' mb={'2rem'}>
            <AlertIcon />
            <Box>
              <AlertTitle>Success!</AlertTitle>
              <AlertDescription>
                Case has been successfully assigned. You can find more details about the case on the cases page.
              </AlertDescription>
            </Box>
            <CloseButton
              alignSelf='flex-start'
              position='relative'
              right={-1}
              top={-1}
              onClick={setSuccessVerification(false)}
            />
          </Alert>
        ) : (
          null
        )
      }

      {
        loading ? (
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue'
            size='xl'
            ml='45%'
          />
        ) : (
          <>
            
            {
              data.length !== 0 ? (
                <>
                  <Grid
                    h='200px'
                    templateColumns='repeat(6, 1fr)'
                    gap={3}
                  >
                    <GridItem colSpan={showDetailsCard ? 4 : 6}>
                      <Authors
                        title={"Select lawyer to assign"}
                        captions={["Name", "Phone Number", "Status", "Email"]}
                        data={data}
                        activeIndex={activeIndex}
                        openSingleFunc={openSingleItem}
                      />
                    </GridItem>
                    {
                      showDetailsCard ? (
                        <GridItem colSpan={2}>
                          <Card p='16px' overflowX={{ sm: "scroll", xl: "hidden" }}>
                            <Flex direction='row'>
                              <CloseButton 
                                onClick={
                                  () => {
                                    setShowDetailsCard(false);
                                    setActiveIndex(null);
                                  }
                                } />
                            </Flex>
                            <CardHeader p='12px 0px 28px 0px'>
                              <Flex direction='row'>
                                <Text fontSize='lg' fontWeight='bold' pb='.5rem'>
                                  Lawyer Details
                                </Text>
                              </Flex>
                            </CardHeader>
                            <CardBody px='5px'>
                              <Stack direction='column' w={'100%'} alignItems='center'>
                                <Avatar 
                                  name={singleData.first_name + ' ' + singleData.last_name}
                                  src={singleData.profile_picture}
                                  size='xl' />
                                <Divider mt={20} />
                                <Text fontSize='sm' color='gray.500' fontWeight='bold' alignSelf='flex-start'>
                                  Name: {singleData.first_name + ' ' + singleData.last_name}
                                </Text>
                                <Divider mt={20} />
                                <Text fontSize='sm' color='gray.500' fontWeight='bold' alignSelf='flex-start'>
                                  Email: {singleData.lawyer_email}
                                </Text>
                                <Divider mt={20} />
                                <Text fontSize='sm' color='gray.500' fontWeight='bold' alignSelf='flex-start'>
                                  Phone Number: {singleData.phone_number}
                                </Text>
                                <Divider mt={20} />
                                <Text fontSize='sm' color='gray.500' fontWeight='bold' alignSelf='flex-start'>
                                  Practicing Cert: 
                                </Text>
                                <Image width={'100%'} src={singleData.practicing_cert} alt='Practicing Cert' />
                                <Divider mt={20} />
                                <Text fontSize='sm' color='gray.500' fontWeight='bold' alignSelf='flex-start'>
                                  Curriculum Vitae:
                                </Text>
                                <Image width={'100%'} src={singleData.curriculum_vitae} alt='Curriculum Vitae' />
                                <Divider mt={20} />
                                <Button 
                                  onClick={assignLawyer} 
                                  isLoading={assignLoading}
                                  loadingText="Assigning...."
                                  colorScheme='green'>Assign Lawyer</Button>
                              </Stack>
                            </CardBody>
                          </Card>
                        </GridItem>
                      ) : (
                        null
                      )
                    }
                  </Grid>
                </>
              ) : (
                <Card p='16px' overflowX={{ sm: "scroll", xl: "hidden" }}>
                  <CardHeader p='12px 0px 28px 0px'>
                    <Flex direction='column'>
                      <Text fontSize='lg' fontWeight='bold' pb='.5rem'>
                        Lawyers
                      </Text>
                      <Flex align='center'>
                        <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                          <Text fontWeight='bold' as='span'>
                            Your lawyers will be listed here
                          </Text>
                        </Text>
                      </Flex>
                    </Flex>
                  </CardHeader>
                  <CardBody px='5px'>
                    <Text fontSize='sm' color='gray.500' fontWeight='bold' textAlign={'center'}>
                      No lawyers yet
                    </Text>
                  </CardBody>
                </Card>
              )
            }
          </>
        )
      }
      
    </Flex>
  );
}

export default CaseLawyers;
