import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component, exact = false, path }) => {
  const authenticated = localStorage.getItem('userDetails') ? true : '';

  return (
    <Route
      exact={exact}
      path={path}
      render={props => (
        authenticated ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to="/auth/login" />
        )
      )} />
  );
}

export default PrivateRoute;