// import
import Dashboard from "views/Dashboard/Dashboard";
import Cases from "views/Dashboard/Cases";
import Case from "views/Dashboard/Case";
import CaseLawyers from "views/Dashboard/CaseLawyers";
import Lawyers from "views/Dashboard/Lawyers";
import Verifications from "views/Dashboard/Verifications";
import Billing from "views/Dashboard/Billing";
import RTLPage from "views/Dashboard/RTL";
import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/cases",
    name: "Cases",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: Cases,
    layout: "/admin",
  },
  {
    path: "/case/:caseId",
    name: "View Case",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: Case,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/assign/lawyer/:caseId",
    name: "Assign Lawyer",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: CaseLawyers,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/lawyers",
    name: "Lawyers",
    rtlName: "لوحة القيادة",
    icon: <PersonIcon color="inherit" />,
    component: Lawyers,
    layout: "/admin",
  },
  {
    path: "/verifications",
    name: "Verifications",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color="inherit" />,
    component: Verifications,
    layout: "/admin",
  },
  {
    path: "/billing",
    name: "Billing",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color="inherit" />,
    component: Billing,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Profile",
    rtlName: "لوحة القيادة",
    icon: <PersonIcon color="inherit" />,
    secondaryNavbar: true,
    component: Profile,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/login",
    name: "Sign In",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color="inherit" />,
    component: SignIn,
    layout: "/auth",
    redirect: true
  },
  /* {
    path: "/signup",
    name: "Sign Up",
    rtlName: "لوحة القيادة",
    icon: <RocketIcon color="inherit" />,
    secondaryNavbar: true,
    component: SignUp,
    layout: "/auth",
    redirect: true
  } */
];
export default dashRoutes;
