import {
  Avatar,
  Badge,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

function TablesTableRow(props) {
  const {
    id,
    profilePicture,
    name,
    phoneNumber,
    status,
    category,
    openSingleFunc,
    activeIndex,
    index,
  } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const categoryColor = useColorModeValue("blue.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");

  return (
    <Tr
      onClick={() => openSingleFunc(id)}
      style={
        (index === activeIndex ? { backgroundColor: "#f4f7fa" } : null,
        { cursor: "pointer" })
      }
    >
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar src={profilePicture} w="50px" borderRadius="12px" me="18px" />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {phoneNumber}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Text
          casing={"capitalize"}
          fontSize="md"
          color={categoryColor}
          fontWeight="bold"
          pb=".5rem"
        >
          {category}
        </Text>
      </Td>
      <Td>
        <Badge
          bg={status === true ? "green.400" : bgStatus}
          color={status === true ? "white" : colorStatus}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {status === true ? "Verified" : "Not Verified"}
        </Badge>
      </Td>
      {/* <Td>
        <Menu>
          <MenuButton 
            as={IconButton}
            aria-label='Options'
            icon={<Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />}
            variant='outline'>
            Actions
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => props.history.push('/admin/lawyer/' + id)}>View Details</MenuItem>
          </MenuList>
        </Menu>
      </Td> */}
    </Tr>
  );
}

export default TablesTableRow;
